<template>
  <router-view></router-view>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { unsetMode } from '@utils/settings/tenant-user.util'
import layoutConstants from '@src/constants/layout-constant.js'
import mainLayoutRoutes from '@router/layouts/main-layout/main-layout-routes.js'
export default {
  name: 'MainLayout',
  beforeRouteUpdate(to, from, next) {
    this.modifyLeftBarDropdown(to.name)
    next()
  },
  data() {
    return {
      TENANT_ROLES,
      unsetModeExcludedList: [TENANT_ROLES.GUARDIAN],
    }
  },
  computed: {
    ...mapGetters('layout', ['userRole']),
    ...mapState({
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),

    showMyProfileTab() {
      return (
        ([TENANT_ROLES.SECTION_TEACHER].includes(this.userRole) && !this.currentCampusScope) ||
        ([TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN].includes(this.userRole) &&
          !this.currentSectionScope)
      )
    },
  },
  page: {
    title: 'Main Layout',
    meta: [{ name: 'description', content: 'Main Layout of MyQampus' }],
  },
  watch: {
    userRole: {
      handler() {
        this.setLeftBar()
      },
    },
  },
  created() {
    if (!this.unsetModeExcludedList.includes(this.userRole)) unsetMode(['student', 'teacher'])
    this.setLeftBar()
  },
  methods: {
    setLeftBar() {
      this.setLeftbarContent({
        customeHeader: 'left-bar-class-list',
        title: this.$route.params.className,
        SHOW_SECTION_DROPDOWN_TO: layoutConstants.showSectionDropdownTo,
        SHOW_CLASS_DROPDOWN_TO: layoutConstants.showClassDropdownTo,
        routes: mainLayoutRoutes,
      })
    },
    ...mapActions('layout', ['setLeftbarContent', 'modifyLeftBarDropdown']),
  },
}
</script>
